// App.tsx

import React, { useState, useEffect } from "react";
import "./App.css";
import Resizer from "react-image-file-resizer";
import Header from "./components/Layout/Header";
import AppRouter from "./pages/Router";
import { RecoilRoot, useRecoilState } from "recoil";
import Loading from "./components/Layout/Loading";
import { loadingAnimationAtoms } from "./recoil/atoms";
import LoadingAnimation from "./components/Layout/LoadingAnimation";
import Layout from "./components/Layout/Layout";

export default function App() {
  return (
    <div className="relative bg-primary-color container max-w-[480px] mx-auto my-0 pt-[16.3rem]">
      <RecoilRoot>
        <Layout />
      </RecoilRoot>
    </div>
  );
}
