import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { useRecoilState } from "recoil";
import {
  loadingAnimationAtoms,
  selectedImageAtoms,
  userDataAtoms,
} from "../recoil/atoms";
import CompletePopup from "../components/Participant/CompletePopup";
import ImagePopup from "../components/Participant/ImagePopup";
import { copyToClipboard } from "../utils/clipboard";
import Loading from "../components/Layout/Loading";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { remToPx } from "../utils/remToPx";
import axios from "axios";
import {
  selfies8turn,
  selfiesDreamCatcher,
  selfiesEl7zup,
  selfiesEvergrow,
  selfiesEvnne,
  selfiesHighlight,
  selfiesHyolin,
  selfiesKard,
  selfiesOhmygirl,
  selfiesRiize,
  selfiesSuperjunior,
  selfiesTempest,
  selfiesTnx,
} from "../constants/achive-selfie";
import SelfiComponent from "../components/Participant/SelfiComponent";
import { isIOS } from "../utils/checkDevice";

const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
const BASE_ID = process.env.REACT_APP_BASE_ID;
const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;

export function ParticipantPage() {
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const printDownRef = useRef<HTMLDivElement>(null);
  const [userData, setUserData] = useRecoilState(userDataAtoms);
  const [imageData, setImageData] = useRecoilState(selectedImageAtoms);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [messageData, setMessageData] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAnimation, setLoadingAnimation] = useRecoilState(
    loadingAnimationAtoms
  );

  async function uploadMessageDataToAirtable() {
    // filePath: string,
    // name: string,
    // since: string,
    // bias: string
    const url = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}/${userData.id}`;

    const payload = {
      fields: {
        Message: messageData,
      },
    };

    const headers = {
      Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      "Content-Type": "application/json",
    };

    const response = await axios.patch(url, payload, { headers });
    return response.data;
  }

  const onChangeMessageData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMessageData(value);
  };

  // const handleDownloadImage = async () => {
  //   if (!printRef?.current) return console.log("no ref");

  //   if (window.confirm("Save this image?")) {
  //     const element = printRef?.current;

  //     let scaleValue = 1; // default value

  //     const m = (2.2 - 20) / (800 - 280);
  //     const b = 20 - 280 * m;

  //     if (window.innerWidth <= 800 && window.innerWidth >= 280) {
  //       scaleValue = m * window.innerWidth + b;
  //     } else if (window.innerWidth > 800) {
  //       scaleValue = 2.2;
  //     } else {
  //       scaleValue = 20;
  //     }

  //     const canvas = await html2canvas(element, {
  //       scale: scaleValue,
  //     });

  //     canvas.toBlob((blob: Blob | null) => {
  //       if (!blob) {
  //         console.error("Failed to convert canvas to blob");
  //         alert("----");
  //         return;
  //       }
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob as Blob);
  //       link.download = `${userData.name}-IDcard.png`;
  //       link.click();
  //       URL.revokeObjectURL(link.href);
  //     }, "image/png");
  //   }
  // };

  function cloneAndScaleElement(ref: React.RefObject<HTMLDivElement>) {
    // 참조된 요소를 복제
    if (!ref.current) return alert("Try again!");

    const clonedElement = ref.current.cloneNode(true) as HTMLDivElement;

    // 복제된 요소의 스타일을 조정하여 3배 확대
    clonedElement.style.transform = "scale(3)";
    clonedElement.style.width = `${ref.current.clientWidth * 3}px`;
    clonedElement.style.height = `${ref.current.clientHeight * 3}px`;
    clonedElement.style.position = "absolute";
    clonedElement.style.top = "-9999px"; // 화면 밖에 위치시킴
    clonedElement.style.left = "-9999px";
    for (let child of Array.from(clonedElement.children)) {
      if (child instanceof HTMLElement) {
        // child.style.width = `${ref.current.clientWidth * 3}px`;
        // child.style.height = `${ref.current.clientHeight * 3}px`;
        // child.style.transform = "scale(3)";
        console.log("child", child);
      }
    }

    const transformElements =
      clonedElement.querySelectorAll(".translate-class");
    transformElements.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.style.transform = "";
      }
    });

    if (
      clonedElement.firstChild instanceof HTMLElement &&
      clonedElement.firstChild.style
    ) {
      clonedElement.firstChild.style.transform = "scale(3)";
      // clonedElement.firstChild.style.width = `${ref.current.clientWidth * 3}px`;
      // clonedElement.firstChild.style.height = `${
      //   ref.current.clientHeight * 3
      // }px`;
    }
    // for (let child of clonedElement.children) {
    //   if (child.style) {
    //     child.style.width = `${ref.current.clientWidth * 3}px`;
    //     child.style.height = `${ref.current.clientHeight * 3}px`;
    //   }
    // }

    return clonedElement;
  }

  const handleDownloadImage = async () => {
    setLoadingAnimation(true);
    if (!printDownRef?.current) {
      setLoadingAnimation(false);
      return console.log("no ref");
    }

    const alertMessage = isIOS()
      ? "Click to save image > Select main album > Save to album"
      : "Click to save image > Automatically save to album";

    if (window.confirm(alertMessage)) {
      const element = printDownRef?.current;

      // const clonedElement = cloneAndScaleElement(printRef);
      // if (!clonedElement) return console.log("no clonedElement");

      // console.log(clonedElement, "clonedElement");

      // document.body.appendChild(element);

      // let scaleValue = 1; // default value

      // const m = (2.2 - 20) / (800 - 280);
      // const b = 20 - 280 * m;

      // if (window.innerWidth <= 800 && window.innerWidth >= 280) {
      //   scaleValue = m * window.innerWidth + b;
      // } else if (window.innerWidth > 800) {
      //   scaleValue = 2.2;
      // } else {
      //   scaleValue = 20;
      // }

      // window.devicePixelRatio = 10;
      const canvas = await html2canvas(element);
      // console.log(canvas, "canvas");
      // document.body.removeChild(clonedElement);

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      // if (data) {
      //   document.body.removeChild(clonedElement);
      // }

      if (typeof link.download === "string") {
        link.href = data;
        link.download = `image.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }

    setLoadingAnimation(false);
  };

  const createMessageData = async () => {
    setLoadingAnimation(true);
    if (messageData.length === 0) {
      alert("Please enter your message.");
      return;
    }

    const response = await uploadMessageDataToAirtable();
    console.log(response, "response");
    if (response.id) {
      openMessagePopup();
    }
    setLoadingAnimation(false);
  };

  const openMessagePopup = () => {
    setMessagePopup(true);
  };

  const closeMessagePopup = () => {
    setMessagePopup(false);
  };

  const openImagePopup = () => {
    setImagePopup(true);
  };

  const closeImagePopup = () => {
    setImagePopup(false);
  };

  const handeSelectedImage = (image: string) => {
    setImageData(image);
  };

  const handleScroll = () => {
    document
      .querySelector("#scroll-area")
      ?.scrollIntoView({ behavior: "smooth" });
    // targetRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (
      !userData?.name ||
      !userData?.since ||
      !userData?.bias ||
      !userData.image ||
      !userData.id
    ) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    // 예를 들어, 네트워크 요청이나 다른 비동기 작업을 여기에서 수행하게 됩니다.
    // 일단은 setTimeout을 사용하여 3초 후 로딩 상태를 false로 변경하도록 하겠습니다.
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);

    // return () => clearTimeout(timer);
  }, []);

  // if (isLoading) return <Loading />;

  return (
    <>
      {messagePopup && (
        <CompletePopup open={messagePopup} closePopup={closeMessagePopup} />
      )}
      {imageData && <ImagePopup />}
      <section className="mx-auto my-0">
        <div className="mx-auto my-0 h-[38.3rem]">
          <LazyLoad height={remToPx(38.3)} once>
            <img src="/assets/participant/section1-deco.png" />
          </LazyLoad>
        </div>
        {/* px-[8.2rem] */}
        <LazyLoad height={remToPx(92)} once>
          <div
            className="relative mx-auto my-0 bg-no-repeat bg-center bg-contain h-[92rem]"
            style={{
              backgroundImage:
                "url('/assets/participant/section1-background.png')",
            }}
          >
            <div
              ref={printRef}
              className="mx-auto my-0 cursor-pointer bg-no-repeat bg-center bg-contain w-[92rem] h-[92rem] pt-[5rem]"
              onClick={handleDownloadImage}
              style={{
                backgroundImage:
                  "url('/assets/participant/section1-card-save.png')",
              }}
            >
              <div className="mx-auto my-0 pt-[12.7rem] w-[51.6rem] h-[82.1rem]">
                <div
                  className="mx-auto w-[33.2rem] h-[39.8rem] rounded-lg"
                  style={{
                    backgroundImage: `url(${userData.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />

                <div>
                  <LazyLoad height={remToPx(51.6)} once>
                    <div
                      className="block transform -translate-y-[48.7rem] mx-auto w-[51.6rem] h-[54.8rem] bg-no-repeat bg-center bg-contain translate-class"
                      style={{
                        backgroundImage: `url('/assets/participant/section1-card-deco.png')`,
                      }}
                    >
                      {/* <img
                      className="block transform -translate-y-[48.7rem] mx-auto w-[51.6rem] h-[54.8rem]"
                      src="/assets/participant/section1-card-deco.png"
                    /> */}
                    </div>
                  </LazyLoad>
                  <div className="mx-auto transform -translate-y-[1rem]">
                    <div className="block flex transform -translate-y-[47.7rem] text-[4.5rem] mx-[4.3rem] h-[7rem] items-center justify-left translate-class">
                      <div className="mb-[2.7rem] h-[4.3rem] flex items-start">
                        <p
                          className="text-[4.5rem] h-full font-medium "
                          style={{
                            textIndent: "11rem",
                            fontFamily: `'Reenie Beanie', cursive`,
                            letterSpacing: "-1.2px",
                            lineHeight: "100%",
                          }}
                        >
                          {userData.name}
                        </p>
                      </div>
                    </div>
                    <div className="block flex transform -translate-y-[47.7rem] text-[4.5rem] mx-[4.3rem] h-[7rem] items-center justify-left">
                      <div className="mb-[2.7rem] h-[4.3rem] flex items-start">
                        <p
                          className="text-[4.5rem] h-full font-medium"
                          style={{
                            textIndent: "27.4rem",
                            fontFamily: `'Reenie Beanie', cursive`,
                            letterSpacing: "-1.2px",
                            lineHeight: "100%",
                          }}
                        >
                          {userData.since}
                        </p>
                      </div>
                    </div>
                    <div className="block flex transform -translate-y-[47.7rem] text-[4.5rem] mx-[4.3rem] h-[7rem] items-center justify-left">
                      <div className="mb-[2.7rem] h-[4.3rem] flex items-start">
                        <p
                          className="text-[4.5rem] h-full font-medium"
                          style={{
                            textIndent: "13.5rem",
                            fontFamily: `'Reenie Beanie', cursive`,
                            letterSpacing: "-1.2px",
                            lineHeight: "100%",
                          }}
                        >
                          {userData.bias}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="block transform -translate-y-[50rem] mx-[19.9rem]">
              <p className="text-[4.5rem]" style={{ textIndent: "15.2rem" }}>
                {userData.name}
              </p>
              <p className="text-[4.5rem]" style={{ textIndent: "32.6rem" }}>
                {userData.since}
              </p>
              <p className="text-[4.5rem]" style={{ textIndent: "18.7rem" }}>
                {userData.bias}
              </p>
            </div> */}
              </div>
            </div>

            {/* <div className="absolute top-[7.6rem] left-[50%] mx-auto my-0 transform -translate-x-1/2 w-[51.6rem] cursor-pointer">
            <img src="/assets/participant/section1-card-deco.png" />
          </div> */}
          </div>
        </LazyLoad>
      </section>
      <section className="mx-auto my-0">
        <LazyLoad height={remToPx(202.8)} offset={100} once>
          <div className="mx-auto my-0 h-[202.8rem]">
            <img src="/assets/participant/section2-content.png" />
          </div>
        </LazyLoad>
        <div className="mx-auto my-0 bg-secondary-color">
          <LazyLoad height={remToPx(41.8)} offset={100} once>
            <div
              className="relative mx-auto my-0 bg-no-repeat bg-center bg-contain w-[98rem] h-[41.8rem] mb-[3rem]"
              style={{
                backgroundImage:
                  "url('/assets/participant/section2-message-form.png')",
              }}
            >
              {/* <img src="/assets/participant/section3-content.png" /> */}
              <input
                type="text"
                onChange={onChangeMessageData}
                name="message"
                className="absolute top-[15.7rem] left-[50%] transform -translate-x-1/2 block w-[58rem] h-[8.3rem] p-[1.8rem] message text-[8rem]"
                style={{
                  fontFamily: `'Reenie Beanie', cursive`,
                }}
                // placeholder="Zhang I love you!"
              />
              <p className="absolute bottom-[6.3rem] right-[6.3rem] text-[3rem] text-white">
                {messageData.length}/50
              </p>
            </div>
          </LazyLoad>
          <LazyLoad height={remToPx(12.8)} offset={100} once>
            <button
              onClick={createMessageData}
              className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[52.5rem] h-[12.8rem] cursor-pointer"
              style={{
                backgroundImage:
                  "url('/assets/participant/section2-message-send-button.png')",
              }}
            />
          </LazyLoad>
        </div>
      </section>
      <section className="mx-auto my-0 bg-secondary-color">
        <LazyLoad height={remToPx(128.8)} offset={100} once>
          <div
            className="relative mx-auto my-0 bg-no-repeat bg-center bg-contain h-[128.8rem]"
            style={{
              backgroundImage:
                "url('/assets/participant/section3-background.png')",
            }}
          >
            <div className="mx-auto my-0 pt-[24.2rem] px-[5.2rem] h-[87.8rem]">
              <LazyLoad height={remToPx(87.8)} offset={100} once>
                <img src="/assets/participant/section3-check-message.png" />
              </LazyLoad>
            </div>
          </div>
        </LazyLoad>
        <LazyLoad height={remToPx(16.2)} offset={100} once>
          <button
            onClick={copyToClipboard}
            className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[16.2rem] h-[16.2rem] cursor-pointer"
            style={{
              backgroundImage:
                "url('/assets/participant/section3-share-button.png')",
            }}
          />
        </LazyLoad>
      </section>
      <section className="mx-auto my-0">
        <LazyLoad height={remToPx(212)} offset={100} once>
          <div
            className="mx-auto my-0 bg-no-repeat bg-center bg-cover h-[212rem]"
            style={{
              backgroundImage:
                "url('/assets/participant/section4-background.png')",
            }}
          />
        </LazyLoad>
        <LazyLoad height={remToPx(12.8)} offset={100} once>
          <div className="bg-black-color">
            <button
              onClick={handleScroll}
              className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[52.6rem] h-[12.8rem] cursor-pointer"
              style={{
                backgroundImage:
                  "url('/assets/participant/section4-scroll-button.png')",
              }}
            />
          </div>
        </LazyLoad>
        <div className="bg-black-color h-[57.8rem]" />
      </section>
      <section className="mx-auto my-0">
        <LazyLoad height={remToPx(211.8)} offset={200} once>
          <div
            id="scroll-area"
            className="mx-auto my-0 bg-no-repeat bg-center bg-cover h-[211.8rem] pt-[50.7rem] pb-[4.4rem]"
            style={{
              backgroundImage:
                "url('/assets/participant/dream-catcher/background.png')",
            }}
          >
            <div className="flex flex-col justify-between relative">
              {selfiesDreamCatcher
                .reduce((acc: any, curr: any, idx) => {
                  if (idx % 3 === 0) {
                    acc.push([curr]);
                  } else {
                    acc[acc.length - 1].push(curr);
                  }
                  return acc;
                }, [])
                .map((group: string[], boxIndex: number) => {
                  if (group.length % 3 !== 0) {
                    for (let i = 0; i <= 3 - group.length; i++) {
                      group.push("");
                    }
                  }
                  return (
                    <div className="flex flex-row items-stretch box-border relative mb-1 px-[4.1rem] justify-between">
                      {group.map((img, feedIndex) => (
                        <>
                          {img && (
                            <div
                              className="w-[32.3rem] h-[51.1rem] rounded-lg cursor-pointer"
                              key={`feed${feedIndex}`}
                              onClick={() => handeSelectedImage(img)}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  );
                })}
            </div>
          </div>
        </LazyLoad>
        <SelfiComponent
          backgroundImage="/assets/participant/el7zup/background.png"
          seflieImages={selfiesEl7zup}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(184.9)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/evergrow/background.png"
          seflieImages={selfiesEvergrow}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(132.1)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/evnne/background.png"
          seflieImages={selfiesEvnne}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(184.9)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/highlight/background.png"
          seflieImages={selfiesHighlight}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(132.1)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/hyolin/background.png"
          seflieImages={selfiesHyolin}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(79.3)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/kard/background.png"
          seflieImages={selfiesKard}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(132.1)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/ohmygirl/background.png"
          seflieImages={selfiesOhmygirl}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(132.1)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/riize/background.png"
          seflieImages={selfiesRiize}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(184.9)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/superjunior/background.png"
          seflieImages={selfiesSuperjunior}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(184.9)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/tempest/background.png"
          seflieImages={selfiesTempest}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(184.9)}
        />
        <SelfiComponent
          backgroundImage="/assets/participant/tnx/background.png"
          seflieImages={selfiesTnx}
          handeSelectedImage={handeSelectedImage}
          height={remToPx(132.1)}
        />
        {/* <SelfiComponent
          backgroundImage="/assets/participant/8turn/background.png"
          seflieImages={selfies8turn}
          handeSelectedImage={handeSelectedImage}
          paddingBottom={true}
          height={remToPx(206.4)}
        /> */}

        <LazyLoad height={remToPx(206.4)} offset={200} once>
          <div
            className={`mx-auto my-0 bg-no-repeat bg-bottom bg-cover pt-[23.8rem] pb-[25.9rem]`}
            style={{
              width: "100%",
              backgroundImage: `url('/assets/participant/8turn/background.png')`,
            }}
          >
            <div className="flex flex-col relative">
              {selfies8turn
                .reduce((acc: any, curr: any, idx) => {
                  if (idx % 3 === 0) {
                    acc.push([curr]);
                  } else {
                    acc[acc.length - 1].push(curr);
                  }
                  return acc;
                }, [])
                .map((group: string[], boxIndex: number) => {
                  if (group.length % 3 !== 0) {
                    for (let i = 0; i <= 3 - group.length; i++) {
                      group.push("");
                    }
                  }
                  return (
                    <div className="flex items-start flex-row items-stretch box-border relative mb-1 px-[4.1rem] justify-between">
                      {group.map((img, feedIndex) => (
                        <>
                          {img ? (
                            <div
                              className="w-[32.3rem] h-[51.1rem] rounded-lg cursor-pointer"
                              key={`feed${feedIndex}`}
                              onClick={() => handeSelectedImage(img)}
                            />
                          ) : (
                            <div
                              className="w-[32.3rem] h-[51.1rem] rounded-lg cursor-pointer"
                              key={`feed${feedIndex}`}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  );
                })}
            </div>
          </div>
        </LazyLoad>

        {/* <div
          className="mx-auto my-0 bg-no-repeat bg-bottom bg-contain min-h-[203.1rem] pb-[18.7rem]"
          style={{
            backgroundImage:
              "url('/assets/participant/section5-secret-achive-end.png')",
          }}
        >
          <div className="flex flex-col relative">
            {[...Array(18)]
              .map(() => "/assets/participant/section5-selfi-1.png")
              .reduce((acc: any, curr: any, idx) => {
                if (idx % 3 === 0) {
                  acc.push([curr]);
                } else {
                  acc[acc.length - 1].push(curr);
                }
                return acc;
              }, [])
              .map((group: string[], boxIndex: number) => {
                if (group.length % 3 !== 0) {
                  for (let i = 0; i <= 3 - group.length; i++) {
                    group.push("");
                  }
                }
                return (
                  <div className="flex flex-row items-stretch box-border relative mb-1 px-[8.5rem] justify-between">
                    {group.map((img, feedIndex) => (
                      <div
                        className="w-[29.5rem] h-[29.5rem] rounded-lg cursor-pointer"
                        key={`feed${feedIndex}`}
                      >
                        {img && (
                          <LazyLoad height={remToPx(29.5)} offset={100} once>
                            <img
                              onClick={() => handeSelectedImage(img)}
                              src={img}
                              className="h-[29.5rem] object-cover rounded-lg"
                            />
                          </LazyLoad>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </div> */}
      </section>
      <footer className="mx-auto my-0 h-[22.43rem]">
        <LazyLoad height={remToPx(22.43)} offset={100} once>
          <img src="/assets/participant/footer-logo.png" />
        </LazyLoad>
      </footer>
      {/* absolute top-[-1000rem] left-[-1000rem] */}
      <div className="absolute top-[-1000rem] left-[-1000rem]">
        <div className="relative">
          <div
            ref={printDownRef}
            className="mx-auto my-0 cursor-pointer bg-no-repeat bg-center bg-contain w-[184rem] h-[184rem] pt-[10rem]"
            onClick={handleDownloadImage}
            style={{
              backgroundImage:
                "url('/assets/participant/section1-card-save.png')",
            }}
          >
            <div className="mx-auto my-0 pt-[25.4rem] w-[103.2rem] h-[164.2rem]">
              <div
                className="mx-auto w-[66.4rem] h-[79.6rem] rounded-lg"
                style={{
                  backgroundImage: `url(${userData.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />

              <div>
                <div
                  className="block transform -translate-y-[97.4rem] mx-auto w-[103.2rem] h-[109.6rem] bg-no-repeat bg-center bg-contain translate-class"
                  style={{
                    backgroundImage: `url('/assets/participant/section1-card-deco.png')`,
                  }}
                ></div>
                <div
                  className="mx-auto"
                  style={{
                    transform: "translateY(-103.5rem)",
                  }}
                >
                  <div className="block flex h-full text-9rem mx-8.6rem mb-[5.1rem] items-center justify-left translate-class">
                    <div className="flex items-start">
                      <p
                        className="text-9rem h-full font-medium"
                        style={{
                          fontSize: "9rem",
                          textIndent: "30rem",
                          fontFamily: `'Reenie Beanie', cursive`,
                          letterSpacing: "-2.4px",
                          lineHeight: "100%",
                        }}
                      >
                        {userData.name}
                      </p>
                    </div>
                  </div>
                  <div className="block flex h-full text-9rem mx-8.6rem mb-[5.1rem] items-center justify-left">
                    <div className="flex items-start">
                      <p
                        className="text-9rem h-full font-medium"
                        style={{
                          fontSize: "9rem",
                          textIndent: "63.6rem",
                          fontFamily: `'Reenie Beanie', cursive`,
                          letterSpacing: "-2.4px",
                          lineHeight: "100%",
                        }}
                      >
                        {userData.since}
                      </p>
                    </div>
                  </div>
                  <div className="block flex h-full text-9rem mx-8.6rem mb-[5.1rem] items-center justify-left">
                    <div className="flex items-start">
                      <p
                        className="text-9rem h-full font-medium"
                        style={{
                          fontSize: "9rem",
                          textIndent: "36rem",
                          fontFamily: `'Reenie Beanie', cursive`,
                          letterSpacing: "-2.4px",
                          lineHeight: "100%",
                        }}
                      >
                        {userData.bias}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="block flex transform -translate-y-[95.4rem] text-[9rem] mx-[8.6rem] h-[13rem] pt-[3rem] pb-[1rem]  items-center justify-left translate-class"
                  style={{
                    borderBottom: "0.4rem solid #FF32DC",
                  }}
                >
                  <div className="h-[10rem] flex items-start">
                    <p
                      className="text-[4rem] h-full"
                      style={{
                        color: "#FF32DC",
                        fontWeight: "bold",
                      }}
                    >
                      NAME
                    </p>
                  </div>
                  <div className="pb-[6rem] h-[14rem] flex items-start">
                    <p
                      className="text-[8rem] h-full font-medium"
                      style={{
                        textIndent: "4rem",
                        fontFamily: `'Reenie Beanie', cursive`,
                        letterSpacing: "-2.4px",
                        lineHeight: "100%",
                      }}
                    >
                      {userData.name}
                    </p>
                  </div>
                </div>
                <div
                  className="block flex transform -translate-y-[95.4rem] text-[9rem] mx-[8.6rem] h-[13rem] pt-[3rem] pb-[1rem]  items-center justify-left translate-class"
                  style={{
                    borderBottom: "0.4rem solid #FF32DC",
                  }}
                >
                  <div className="h-[10rem] flex items-start">
                    <p
                      className="text-[4rem] h-full"
                      style={{
                        color: "#FF32DC",
                        fontWeight: "bold",
                      }}
                    >
                      K-POP-FAN SINCE
                    </p>
                  </div>
                  <div className="pb-[6rem] h-[14rem] flex items-start">
                    <p
                      className="text-[8rem] h-full font-medium"
                      style={{
                        textIndent: "4rem",
                        fontFamily: `'Reenie Beanie', cursive`,
                        letterSpacing: "-2.4px",
                        lineHeight: "100%",
                      }}
                    >
                      {userData.since}
                    </p>
                  </div>
                </div>
                <div
                  className="block flex transform -translate-y-[95.4rem] text-[9rem] mx-[8.6rem] h-[13rem] pt-[3rem] pb-[1rem]  items-center justify-left translate-class"
                  style={{
                    borderBottom: "0.4rem solid #FF32DC",
                  }}
                >
                  <div className="h-[10rem] flex items-start">
                    <p
                      className="text-[4rem] h-full"
                      style={{
                        color: "#FF32DC",
                        fontWeight: "bold",
                      }}
                    >
                      My Bias
                    </p>
                  </div>
                  <div className="pb-[6rem] h-[14rem] flex items-start">
                    <p
                      className="text-[8rem] h-full font-medium"
                      style={{
                        textIndent: "4rem",
                        fontFamily: `'Reenie Beanie', cursive`,
                        letterSpacing: "-2.4px",
                        lineHeight: "100%",
                      }}
                    >
                      {userData.bias}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
