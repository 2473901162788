import { useEffect, useRef } from "react";
import Popup from "../Layout/Popup";

interface CompletePopupProps {
  open?: boolean;
  closePopup?: () => void;
}

const CompletePopup: React.FC<CompletePopupProps> = ({ open, closePopup }) => {
  const modalRef = useRef<HTMLInputElement>(null);

  const handleFocusOutOutside = (e: any) => {
    e.stopPropagation();
    // console.log(open, "open");
    if (open && modalRef && !modalRef.current?.contains(e.target)) {
      console.log("closePopup");
      closePopup?.();
    }
  };

  useEffect(() => {
    // console.log(selectedPost, "selectedPost");
    if (window && document) {
      // window.addEventListener("click", handleFocusOutOutside);
      document.body.style.overflow = "hidden";
      return () => {
        // window.removeEventListener("click", handleFocusOutOutside);
        document.body.style.overflow = "unset";
      };
    }
  }, []);

  return (
    <Popup closePopup={closePopup}>
      <div
        ref={modalRef}
        className="w-[89.6rem] h-[33.3rem] bg-no-repeat bg-center bg-contain"
        style={{
          backgroundImage: `url('/assets/participant/section3-message-popup.png')`,
        }}
      >
        <button
          onClick={closePopup}
          className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[41.2rem] h-[9.2rem] cursor-pointer mt-[19rem]"
          style={{
            backgroundImage:
              "url('/assets/participant/section3-message-popup-exit.png')",
          }}
        />
      </div>
    </Popup>
  );
};

export default CompletePopup;
