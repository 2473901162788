export const selfiesDreamCatcher = [
  "/assets/participant/dream-catcher/dream-catcher1.png",
  "/assets/participant/dream-catcher/dream-catcher2.png",
  "/assets/participant/dream-catcher/dream-catcher3.png",
  "/assets/participant/dream-catcher/dream-catcher4.png",
  "/assets/participant/dream-catcher/dream-catcher5.png",
  "/assets/participant/dream-catcher/dream-catcher6.png",
  "/assets/participant/dream-catcher/dream-catcher7.png",
];

export const selfiesEl7zup = [
  "/assets/participant/el7zup/el7zup1.png",
  "/assets/participant/el7zup/el7zup2.png",
  "/assets/participant/el7zup/el7zup3.png",
  "/assets/participant/el7zup/el7zup4.png",
  "/assets/participant/el7zup/el7zup5.png",
  "/assets/participant/el7zup/el7zup6.png",
  "/assets/participant/el7zup/el7zup7.png",
];

export const selfiesEvergrow = [
  "/assets/participant/evergrow/evergrow1.png",
  "/assets/participant/evergrow/evergrow2.png",
  "/assets/participant/evergrow/evergrow3.png",
  "/assets/participant/evergrow/evergrow4.png",
  "/assets/participant/evergrow/evergrow5.png",
  "/assets/participant/evergrow/evergrow6.png",
];

export const selfiesEvnne = [
  "/assets/participant/evnne/evnne1.png",
  "/assets/participant/evnne/evnne2.png",
  "/assets/participant/evnne/evnne3.png",
  "/assets/participant/evnne/evnne4.png",
  "/assets/participant/evnne/evnne5.png",
  "/assets/participant/evnne/evnne6.png",
  "/assets/participant/evnne/evnne7.png",
];

export const selfiesHighlight = [
  "/assets/participant/highlight/highlight1.png",
  "/assets/participant/highlight/highlight2.png",
  "/assets/participant/highlight/highlight3.png",
  "/assets/participant/highlight/highlight4.png",
];

export const selfiesHyolin = ["/assets/participant/hyolin/hyolin1.png"];

export const selfiesKard = [
  "/assets/participant/kard/kard1.png",
  "/assets/participant/kard/kard2.png",
  "/assets/participant/kard/kard3.png",
  "/assets/participant/kard/kard4.png",
];

export const selfiesOhmygirl = [
  "/assets/participant/ohmygirl/ohmygirl1.png",
  "/assets/participant/ohmygirl/ohmygirl2.png",
  "/assets/participant/ohmygirl/ohmygirl3.png",
  "/assets/participant/ohmygirl/ohmygirl4.png",
  "/assets/participant/ohmygirl/ohmygirl5.png",
  "/assets/participant/ohmygirl/ohmygirl6.png",
];

export const selfiesRiize = [
  "/assets/participant/riize/riize1.png",
  "/assets/participant/riize/riize2.png",
  "/assets/participant/riize/riize3.png",
  "/assets/participant/riize/riize4.png",
  "/assets/participant/riize/riize5.png",
  "/assets/participant/riize/riize6.png",
  "/assets/participant/riize/riize7.png",
];

export const selfiesSuperjunior = [
  "/assets/participant/superjunior/superjunior1.png",
  "/assets/participant/superjunior/superjunior2.png",
  "/assets/participant/superjunior/superjunior3.png",
  "/assets/participant/superjunior/superjunior4.png",
  "/assets/participant/superjunior/superjunior5.png",
  "/assets/participant/superjunior/superjunior6.png",
  "/assets/participant/superjunior/superjunior7.png",
];

export const selfiesTempest = [
  "/assets/participant/tempest/tempest1.png",
  "/assets/participant/tempest/tempest2.png",
  "/assets/participant/tempest/tempest3.png",
  "/assets/participant/tempest/tempest4.png",
  "/assets/participant/tempest/tempest5.png",
  "/assets/participant/tempest/tempest6.png",
  "/assets/participant/tempest/tempest7.png",
];

export const selfiesTnx = [
  "/assets/participant/tnx/tnx1.png",
  "/assets/participant/tnx/tnx2.png",
  "/assets/participant/tnx/tnx3.png",
  "/assets/participant/tnx/tnx4.png",
  "/assets/participant/tnx/tnx5.png",
];

export const selfies8turn = [
  "/assets/participant/8turn/8turn1.png",
  "/assets/participant/8turn/8turn2.png",
  "/assets/participant/8turn/8turn3.png",
  "/assets/participant/8turn/8turn4.png",
  "/assets/participant/8turn/8turn5.png",
  "/assets/participant/8turn/8turn6.png",
  "/assets/participant/8turn/8turn7.png",
  "/assets/participant/8turn/8turn8.png",
];
