import { useEffect, useRef } from "react";
import Popup from "../Layout/Popup";
import html2canvas from "html2canvas";
import { useRecoilState } from "recoil";
import { selectedImageAtoms, userDataAtoms } from "../../recoil/atoms";
import { isIOS } from "../../utils/checkDevice";

interface ImagePopupProps {
  open?: boolean;
  closePopup?: () => void;
}

const ImagePopup: React.FC<ImagePopupProps> = ({ open }) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLInputElement>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const printDownRef = useRef<HTMLDivElement>(null);
  const [image, setImage] = useRecoilState(selectedImageAtoms);

  const closePopup = () => {
    if (image) {
      setImage("");
    }
  };

  const handleDownloadImage = async () => {
    if (!printDownRef?.current) return console.log("no ref");

    const alertMessage = isIOS()
      ? "Click to save image > Select main album > Save to album"
      : "Click to save image > Automatically save to album";

    if (window.confirm(alertMessage)) {
      const element = printDownRef?.current;
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = "selfi.jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  const handleFocusOutOutside = (e: any) => {
    e.stopPropagation();
    if (image && modalRef && !modalRef.current?.contains(e.target)) {
      closePopup?.();
    }
  };

  useEffect(() => {
    // console.log(selectedPost, "selectedPost");
    if (window && document) {
      // backgroundRef.current?.addEventListener("click", handleFocusOutOutside);
      document.body.style.overflow = "hidden";
      return () => {
        // backgroundRef.current?.removeEventListener(
        //   "click",
        //   handleFocusOutOutside
        // );
        document.body.style.overflow = "unset";
      };
    }
  }, []);

  return (
    <Popup closePopup={closePopup}>
      <div
        ref={modalRef}
        className="w-[89.6rem] h-[154.1rem] bg-no-repeat bg-center bg-contain"
        style={{
          backgroundImage: `url('/assets/participant/section5-image-popup.png')`,
        }}
      >
        <div className="h-[9rem] flex justify-end pr-[2.2rem]">
          <button
            className="w-[9rem] cursor-pointer"
            onClick={closePopup}
          ></button>
        </div>

        <div
          ref={printRef}
          onClick={handleDownloadImage}
          className="w-[87.6rem] h-[143.2rem] bg-no-repeat bg-center bg-cover"
          // style={{
          //   backgroundImage: `url(${image})`,
          // }}
        >
          <img src={image} className="h-[143.2rem] object-contain" />
        </div>
        <div className="absolute top-[-1000rem] left-[-1000rem]">
          <div
            ref={printDownRef}
            onClick={handleDownloadImage}
            className="w-[175.2rem] h-[286.4rem] bg-no-repeat bg-center bg-cover cursor-pointer"
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
        </div>
      </div>
    </Popup>
  );
};

export default ImagePopup;
