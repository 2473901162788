import { useEffect, useRef } from "react";

interface PopupProps {
  children?: React.ReactNode;
  closePopup?: () => void;
}

const Popup: React.FC<PopupProps> = ({ children, closePopup }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
      {children}
    </div>
  );
};

export default Popup;
