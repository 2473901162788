export const copyToClipboard = async () => {
  const textToCopy =
    "Dear KCONer,\n\nEnter your KPOP fan profile\nand receive your DIGITAL KCONer ID CARD.\nAfter obtaining it, use the hashtag #KCONSAUDIARABIA2023\nto receive an artist signed Polaroid as a prize.\nJoin right away👇\nLink: https://kconevent.com/";

  let textArea; // 상위 스코프에서 변수 선언

  try {
    // 먼저 Clipboard API 시도
    await navigator.clipboard.writeText(textToCopy);
    alert("Copied to clipboard!");
  } catch (apiError) {
    try {
      // Clipboard API 실패 시, execCommand 방식으로 시도
      textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();

      const successful = document.execCommand("copy");
      if (successful) {
        alert("Copied to clipboard");
      } else {
        throw new Error("execCommand failed");
      }
    } catch (execCommandError: any) {
      // 두 방법 모두 실패한 경우
      alert(`Failed to copy text! Error: ${execCommandError.message}`);
    } finally {
      if (textArea && document.body.contains(textArea)) {
        document.body.removeChild(textArea);
      }
    }
  }
};

// export const copyToClipboard = async () => {
//   try {
//     // 뛰어쓰기를 포함한 텍스트
//     const textToCopy =
//       "Dear KCONer,\n\nEnter your KPOP fan profile\nand receive your DIGITAL KCONer ID CARD.\nAfter obtaining it, use the hashtag #KCONSAUDIARABIA2023\nto receive an artist signed Polaroid as a prize.\nJoin right away👇\nLink: https://kconevent.com/";

//     // 텍스트 복사
//     await navigator.clipboard.writeText(textToCopy);

//     // 알림 표시
//     alert("Copied to clipboard!");
//   } catch (err) {
//     alert("Failed to copy text!");
//   }
// };
