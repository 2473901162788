import { useEffect } from "react";

const Loading = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto my-0 w-[90rem] min-h-[100vh] h-full py-[26rem]">
      <img src="/assets/layout/loading-moving.gif" />
    </div>
  );
};

export default Loading;
