// const LoadingAnimation = () => {
//   return (
//     <div className="mx-auto my-0 w-[90rem] min-h-[100vh] h-full py-[26rem]">
//       <img src="/assets/layout/loading-image.png" />
//     </div>
//   );
// };

// export default LoadingAnimation;

// Copy code
import React from "react";
import animationData from "../../assets/loading-animation.json";
import Lottie from "react-lottie";
import { remToPx } from "../../utils/remToPx";

const LottieComponent: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={remToPx(40)}
      width={remToPx(40)}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      }}
    />
  );
};

export default LottieComponent;
