import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { UserDataSchema } from "../types/user";

const sessionStorage =
  typeof window !== "undefined" ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: sessionStorage, // configure which storage will be used to store the data
  converter: JSON, // configure how values will be serialized/deserialized in storage
});

export const userDataAtoms = atom<UserDataSchema>({
  key: "userDataAtoms",
  default: {
    name: "",
    since: "",
    bias: "",
    image: "",
    id: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const selectedImageAtoms = atom<string>({
  key: "selectedImageAtoms",
  default: "",
});

export const loadingAnimationAtoms = atom<boolean>({
  key: "loadingAnimationAtoms",
  default: false,
});

export const loadingImageAnimationAtoms = atom<boolean>({
  key: "loadingImageAnimationAtoms",
  default: false,
});

export const newImageAtoms = atom<File | null>({
  key: "newImageAtoms",
  default: null,
});
