import LazyLoad from "react-lazyload";
import { remToPx } from "../../utils/remToPx";

interface SelfiComponentProps {
  backgroundImage: string;
  seflieImages: string[];
  handeSelectedImage: (img: string) => void;
  paddingBottom?: boolean;
  height: number;
}

const SelfiComponent: React.FC<SelfiComponentProps> = ({
  backgroundImage,
  seflieImages,
  handeSelectedImage,
  paddingBottom,
  height,
}) => {
  const paddingBottomValue = paddingBottom
    ? `${remToPx(25.9)}`
    : `${remToPx(4.4)}`;

  return (
    <LazyLoad height={height} offset={200} once>
      <div
        className={`mx-auto my-0 bg-no-repeat bg-bottom bg-cover pt-[23.8rem]`}
        style={{
          width: "100%",
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <div className="flex flex-col relative">
          {seflieImages
            .reduce((acc: any, curr: any, idx) => {
              if (idx % 3 === 0) {
                acc.push([curr]);
              } else {
                acc[acc.length - 1].push(curr);
              }
              return acc;
            }, [])
            .map((group: string[], boxIndex: number) => {
              if (group.length % 3 !== 0) {
                for (let i = 0; i <= 3 - group.length; i++) {
                  group.push("");
                }
              }
              return (
                <div className="flex items-start flex-row items-stretch box-border relative mb-1 px-[4.1rem] justify-between">
                  {group.map((img, feedIndex) => (
                    <>
                      {img ? (
                        <div
                          className="w-[32.3rem] h-[51.1rem] rounded-lg cursor-pointer"
                          key={`feed${feedIndex}`}
                          onClick={() => handeSelectedImage(img)}
                        />
                      ) : (
                        <div
                          className="w-[32.3rem] h-[51.1rem] rounded-lg cursor-pointer"
                          key={`feed${feedIndex}`}
                        />
                      )}
                    </>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
    </LazyLoad>
  );
};

export default SelfiComponent;
