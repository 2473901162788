import { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { UserDataSchema } from "../types/user";
import {
  loadingAnimationAtoms,
  loadingImageAnimationAtoms,
  newImageAtoms,
  userDataAtoms,
} from "../recoil/atoms";
import { copyToClipboard } from "../utils/clipboard";
import LazyLoad from "react-lazyload";
import { remToPx } from "../utils/remToPx";
import axios from "axios";
import heic2any from "heic2any";
import AWS, { S3 } from "aws-sdk";
import Loading from "../components/Layout/Loading";

export function HomePage() {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newImage, setNewImage] = useRecoilState(newImageAtoms);
  const [userData, setUserData] = useRecoilState(userDataAtoms);
  const [loadingAnimation, setLoadingAnimation] = useRecoilState(
    loadingAnimationAtoms
  );
  const [loadingImageAnimation, setLoadingImageAnimation] = useRecoilState(
    loadingImageAnimationAtoms
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const onChangeUserData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prev: UserDataSchema) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onClickCreateUser = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoadingAnimation(true);
    setIsCreateLoading(true);

    try {
      if (!userData?.name || !userData?.since || !userData?.bias || !newImage) {
        console.log(userData?.name, userData?.since, userData?.bias, newImage);
        setIsError(true);
        setLoadingAnimation(false);
        return;
      }

      const responseTextData = await uploadTextDataToAirtable();
      setUserData({
        ...userData,
        id: responseTextData.id,
      });

      console.log("responseTextData", responseTextData);

      const responseS3 = await handleUploadToS3();

      if (!responseS3?.Location) {
        setLoadingAnimation(false);
        return;
      }
      console.log("responseS3", responseS3);

      const responseFromArtable = await handleUploadToAirtable(
        responseS3?.Location,
        responseTextData.id
      );

      console.log("responseFromArtable", responseFromArtable);

      if (responseTextData.id && responseFromArtable) {
        // const responseImageData = await uploadImageDataToAirtable();
        // console.log("responseImageData", responseImageData);
        navigate("/participant");
      }
    } catch (error) {
      setIsCreateLoading(false);
    } finally {
      setIsCreateLoading(false);
    }
    setLoadingAnimation(false);

    // const responseImageData = await uploadImageDataToAirtable();
    // console.log("responseImageData", responseImageData);

    // if (responseTextData.data && responseImageData.data) {
    //   // navigate("/participant");
    //   console.log("responseTextData.data", responseTextData.data);
    //   console.log("responseImageData.data", responseImageData.data);
    // }
    // navigate("/participant");

    //통신 - 로딩 - 실패하면 다시 입력하게 만들기 성공하면 다음 페이지 넘기기
  };
  const convertHEICToJPEG = async (file: File) => {
    if (file.type === "image/heic") {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1, // Adjust this as needed (between 0 and 1)
      });
      return new File([convertedBlob as Blob], "converted.jpeg", {
        type: "image/jpeg",
      });
    }
    return file;
  };

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        812,
        1046,
        "WEBP",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        undefined,
        1046
      );
    });

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingAnimation(true);
    setLoadingImageAnimation(true);
    if (!event.target?.files) return setLoadingAnimation(false);
    try {
      const file = event.target?.files[0];
      const convertedFile = await convertHEICToJPEG(file);
      const image = await resizeFile(convertedFile);

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target?.result as string;
          //  setBase64(base64String);
          setUserData({
            ...userData,
            image: base64String as string,
          });
        };
        reader.readAsDataURL(convertedFile);
      }

      setNewImage(file);
      // setUserData({
      //   ...userData,
      //   image: image as string,
      // });
    } catch (err) {
      console.log(err);
      alert("Please try again");
    } finally {
      setLoadingAnimation(false);
      setLoadingImageAnimation(false);
    }
  };

  const handleDivClick = () => {
    // file input을 클릭하여 파일 선택창을 연다.
    fileInputRef.current?.click();
  };

  // useEffect(() => {
  //   // 예를 들어, 네트워크 요청이나 다른 비동기 작업을 여기에서 수행하게 됩니다.
  //   // 일단은 setTimeout을 사용하여 3초 후 로딩 상태를 false로 변경하도록 하겠습니다.
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 100);

  //   return () => clearTimeout(timer);
  // }, []);

  // if (isLoading) return <Loading />;
  const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
  const BASE_ID = process.env.REACT_APP_BASE_ID;
  const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;

  async function uploadTextDataToAirtable() {
    // filePath: string,
    // name: string,
    // since: string,
    // bias: string
    try {
      const payload = {
        fields: {
          Name: userData.name,
          Since: userData.since,
          Bias: userData.bias,
        },
      };

      const headers = {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        "Content-Type": "application/json",
      };

      if (userData.id) {
        const url = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}/${userData.id}`;
        const response = await axios.patch(url, payload, { headers });
        return response.data;
      }

      const url = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`;
      const response = await axios.post(url, payload, { headers });
      return response.data;
    } catch (error) {
      alert("Please try again");
    }
  }

  // async function uploadImageDataToAirtable() {
  //   // filePath: string,
  //   // name: string,
  //   // since: string,
  //   // bias: string
  //   const url = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`;

  //   const data = new FormData();
  //   data.append("fields[Image]", newImage);

  //   const headers = {
  //     Authorization: `Bearer ${AIRTABLE_API_KEY}`,
  //     "Content-Type": "multipart/form-data",
  //   };

  //   const response = await axios.post(url, data, { headers });
  //   return response.data;
  // }

  const handleUploadToAirtable = async (s3Url: string, dataId: string) => {
    if (!s3Url) return;

    const endPoint = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}/${dataId}`;

    try {
      const response = await axios.patch(
        endPoint,
        {
          fields: {
            Image: [
              {
                url: s3Url,
              },
            ],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${AIRTABLE_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Uploaded to Airtable successfully!");
      return response;
    } catch (error) {
      console.error("Error uploading to Airtable:", error);
      alert("Please try again");
    }
  };

  const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
  const AWS_REGION = process.env.REACT_APP_S3_AWS_REGION;

  const handleUploadToS3 = async () => {
    if (!newImage) return;

    const s3 = new AWS.S3({
      region: AWS_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID as string,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY as string,
    });

    const params: S3.Types.PutObjectRequest = {
      Bucket: S3_BUCKET_NAME as string,
      Key: `images/${userData.name}-${userData.since}-${userData.bias}-${newImage.name}`,
      Body: newImage,
      // ACL: "public-read", // 이 설정은 파일이 공개적으로 읽을 수 있게 합니다.
    };

    try {
      const data = await s3.upload(params).promise();

      return data;
    } catch (error) {
      console.error("There was an error uploading the file:", error);
      alert("Please try again");
    }
  };

  if (isCreateLoading) return <Loading />;

  return (
    <>
      <section className="mx-auto my-0 pt-[8.4rem] pb-[9.2rem]">
        <div className="mx-auto my-0 w-[98rem] h-[124rem]">
          <LazyLoad height={remToPx(124)} offset={100} once>
            {/* <img src="/assets/home/main-image.png" alt="main-logo" /> */}
            <img src="/assets/home/main-logo.png" alt="main-logo" />
          </LazyLoad>
        </div>
      </section>
      <section className="relative">
        <div className="relative mx-auto my-0 mb-[3.8rem] h-[185.4rem]">
          <LazyLoad height={remToPx(185.4)} offset={100} once>
            <img src="/assets/home/IDcard-generater.png" />
          </LazyLoad>
        </div>
        <div
          className="absolute top-[21.5rem] w-[40.6rem] h-[52.26rem] left-[50%] transform -translate-x-1/2 cursor-pointer"
          onClick={handleDivClick}
        >
          <input ref={fileInputRef} type="file" onChange={onChange} hidden />
          {newImage ? (
            <div
              style={{ backgroundImage: `url(${userData.image})` }}
              className="bg-cover bg-center w-full h-full rounded-lg-[1rem]"
            />
          ) : (
            <div
              style={{
                backgroundImage: `url('/assets/home/IDcard-photo-box.png')`,
              }}
              className="bg-cover bg-center w-full h-full"
            />
          )}
        </div>

        <div className="absolute flex flex-col justify-between top-[114.2rem] w-[86.6rem] h-[56rem] left-[50%] transform -translate-x-1/2 cursor-pointer">
          <input
            type="text"
            onChange={onChangeUserData}
            defaultValue={userData.name || ""}
            name="name"
            className="block h-[8.3rem] p-[1.8rem] text-[4.7rem] w-full"
            placeholder="Enter Information"
          />
          <input
            type="text"
            onChange={onChangeUserData}
            defaultValue={userData.since || ""}
            name="since"
            className="block h-[8.3rem] p-[1.8rem] text-[4.7rem] w-full"
            placeholder="Enter Information"
          />
          <input
            type="text"
            onChange={onChangeUserData}
            defaultValue={userData.bias || ""}
            name="bias"
            className="block h-[8.3rem] p-[1.8rem] text-[4.7rem] w-full"
            placeholder="Enter Information"
          />
        </div>
        <LazyLoad height={remToPx(12.8)} offset={100} once>
          <button
            className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[52.6rem] h-[12.8rem] cursor-pointer"
            style={{
              backgroundImage: "url('/assets/home/IDcard-save-button.png')",
            }}
            onClick={onClickCreateUser}
          />
        </LazyLoad>
        {isError && (
          <div className="mx-auto m-[2.314rem] px-[9.2rem]">
            <img src="/assets/home/IDcard-error.png" />
          </div>
        )}
      </section>
      <section className="pb-[48rem]">
        <LazyLoad height={remToPx(18.4)} offset={100} once>
          <div className="mx-auto my-0 h-[18.4rem]">
            <img src="/assets/home/share-description.png" />
          </div>
        </LazyLoad>
        <LazyLoad height={remToPx(20)} offset={100} once>
          <button
            onClick={copyToClipboard}
            className="block mx-auto my-0 bg-no-repeat bg-center bg-contain w-[20rem] h-[20rem] cursor-pointer"
            style={{
              backgroundImage: "url('/assets/home/share-button.png')",
            }}
          />
        </LazyLoad>
      </section>
    </>
  );
}
