import { RecoilRoot, useRecoilState } from "recoil";
import Header from "./Header";
import AppRouter from "../../pages/Router";
import LoadingAnimation from "./LoadingAnimation";
import {
  loadingAnimationAtoms,
  loadingImageAnimationAtoms,
} from "../../recoil/atoms";

function Layout() {
  const [loadingImageAnimation, setLoadingImageAnimation] = useRecoilState(
    loadingImageAnimationAtoms
  );
  return (
    <>
      {loadingImageAnimation && <LoadingAnimation />}
      <Header />
      <AppRouter />
    </>
  );
}

export default Layout;
