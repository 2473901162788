import LazyLoad from "react-lazyload";
import { remToPx } from "../../utils/remToPx";

function Header() {
  return (
    <header className="fixed top-0 max-w-[480px] w-[100%] mx-auto my-0 bg-primary-color flex items-center justify-center pt-[3.7rem] pb-[4.1rem] z-10 shadow-custom-shadow">
      <a href="/" className="cursor-pointer">
        <div className="w-[35.2rem] h-[8.5rem]">
          <LazyLoad height={remToPx(8.5)} once>
            <img src="/assets/layout/header-kcon-logo.png" alt="KCON-header" />
          </LazyLoad>
        </div>
      </a>
    </header>
  );
}

export default Header;
